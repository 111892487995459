import React from "react"
import Layout from "../components/layout"
import { Table, Tbody, Tr, Td } from "@chakra-ui/react"
import Elink from "../components/elink"

export default function Home() {
  return (
    <Layout title="Relevante Zertifizierungen" slug="references/">
      <Table variant="simple">
        <Tbody>
          <Tr>
            <Td>2017</Td>
            <Td>Grundlagen Medizin</Td>
            <Td>
              <Elink
                title="Apollon Hochschule der Gesundheitswirtschaft"
                href="https://www.apollon-hochschule.de/"
              >
                Apollon Hochschule der Gesundheitswirtschaft, Bremen
              </Elink>
            </Td>
          </Tr>
          <Tr>
            <Td>2019</Td>
            <Td>200h Hatha Yoga</Td>
            <Td>
              <Elink
                title="Helen Meyer - Yogibar Berlin"
                href="https://www.helenmeyer.de/"
              >
                Helen Meyer, Berlin
              </Elink>
            </Td>
          </Tr>
          <Tr>
            <Td>2020</Td>
            <Td>300h Hatha Yoga</Td>
            <Td>
              <Elink
                title="Helen Meyer - Yogibar Berlin"
                href="https://www.helenmeyer.de/"
              >
                Helen Meyer, Berlin
              </Elink>
            </Td>
          </Tr>
          <Tr>
            <Td>2021</Td>
            <Td>50h Relax and Renew® Restorative Yoga Level 1</Td>
            <Td>
              <Elink
                title="Judith Hanson Lasater, Ph.D. Physical Therapist"
                href="https://www.judithhansonlasater.com/"
              >
                Judith Hanson Lasater, Ph.D. Physical Therapist, San Francisco
              </Elink>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Layout>
  )
}
